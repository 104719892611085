<template>
  <div class="org-tree content-block">
    <fm-title title-text="机构 \ 职务"></fm-title>
    <div class="org-tree-c">
      <fm-tree v-loadingx="loading" :nodes="showNodes" :node-render="nodeRender" search is-selected></fm-tree>
    </div>
  </div>
</template>

<script>
import OrgLabel from './orgLabel'

export default {
  props: {
    isSelect: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      loading: false,
    }
  },
  computed: {
    nodes () {
      let fn = (data) => {
        return {
          title: data.code + ' ' + data.name,
          data: data
        }
      }
      let result = this.$store.getters.orgTree.map(fn)
      let each = (nodes) => {
        nodes.forEach(node => {
          node.children = node.data.child ? node.data.child.map(fn) : []
          let positions = node.data.positions || []
          positions.forEach(v2 => {
            node.children.push({
              title: v2.name,
              data: v2
            })
          })
          node.children
          each(node.children)
        })
      }
      each(result)
      return result
    },
    showNodes () {
      return this.nodes
    }
  },
  created () {
    this.loadData()
  },
  methods: {
    change (data) {
      this.$emit('change', data)
    },
    nodeRender (h, data, nodeVm) {
      return h(OrgLabel, {
        props: {
          data: data,
          isSelect: this.isSelect,
          nodeVm
        },
        on: {
          change: (dataItem) => {
            this.change(dataItem)
          }
        }
      })
    },
    async loadData () {
      this.loading = true
      await this.$store.dispatch('loadOrgTree')
      this.loading = false
    }
  }
}
</script>

<style lang="less" scoped>
.org-tree {
  width: 100%;
  height: 100%;
  background: #fff;
  position: relative;
}
.search-c {
  position: absolute;
  top: 12px;
  left: 127px;
}
.org-tree-c {
  margin: 1rem;
  height: calc(100% - 48px - 2rem); 
  overflow: auto;
}
</style>