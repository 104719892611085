<template>
  <div class="org-form-dev">
    <a :class="wrapClass" class="org-form" href="javascript:;" @click="modalOrg = true">{{valueLabel}}</a>
    <fm-modal v-model="modalOrg" :width="$store.getters.clientType === 'wap' ? '100%' : '800px'" theme="mh-blackt">
      <div style="width:100%;height:70vh;">
        <org-tree :is-select="true" @change="change"></org-tree>
      </div>
    </fm-modal>
  </div>
</template>

<script>
import OrgTree from './orgTree'
import Vue from 'vue'

export default {
  name: 'AssetsTypeForm',
  components: {
    OrgTree
  },
  data () {
    return {
      modalOrg: false,
      onError: false
    }
  },
  props: {
    value: Number,
    disabled: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: ''
    }
  },
  model: {
    prop: 'value',
    event: 'change'
  },
  computed: {
    valueLabel () {
      let label = ''
      if (this.value) {
        let data = this.$store.getters.positionList.find(v => v.data.id === this.value)
        if (data) {
          label = (data.data.code || '') + ' ' + (data.data.name || '')
        }
      }
      return label
    },
    wrapClass () {
      return {
        'verifier-error': this.onError
      }
    }
  },
  methods: {
    verifier () {
      const msg = this.$verifier.check(this.value, false, [])
      console.log('msg verifier', msg, msg === '')
      this.$emit('verifier', msg)
      this.onError = msg !== ''
      return msg
    },
    change (data) {
      this.modalOrg = false
      this.$emit('change', data.id)
      Vue.nextTick(() => {
        this.verifier()
      })
    }
  }
}
</script>

<style lang="less" scope>
.org-form {
  width: 100%;
  border-radius: 4px;
  transition: all 0.2s;
  display: inline-flex;
  box-sizing: border-box;
  padding: 0;
  border: 1px solid #dcdee2;
  align-items: center;
  box-shadow: 0 0 0px 3px transparent;
  font-size: 14px;
  color: #515a6e;
  padding: 0 8px;
  min-height: 30px;
}
.org-form-dev {
  // 报错设置
  .verifier-error {
    border-color: red;
  }
}
</style>