<template>
  <div class="org-label" @click="clickIt">
    <span @click.stop="chooseIt" :class="{'p_label': type === 'position', 'g_label': type === 'org'}">{{ (data.code || '') + ' ' + (data.name || '')}}</span>
    <fm-btn v-if="isSelect && data.orgId" @click="chooseIt" class="org-label-btn" style="margin-left: 1rem;" size="small">选择</fm-btn>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object
    },
    isSelect: {
      type: Boolean
    },
    nodeVm: {
      type: Object
    }
  },
  computed: {
    type () {
      let type = null
      if (this.data && Object.keys(this.data).includes('orgId')) {
        type = 'position'
      } else {
        type = 'org'
      }
      return type
    }
  },
  methods: {
    clickIt () {
      this.nodeVm.openThis(true)
    },
    chooseIt () {
      if (this.type === 'position') {
        this.$emit('change', this.data)
      } else {
        this.clickIt()
      }
    }
  }
}
</script>

<style lang="less" scoped>
.org-label {
  display: flex;
  align-items: center;
  cursor: pointer;
  border-radius: 4px;
  padding: 0 5px;
  height: 22px;
  .p_label {
    color: #F4628F;
  }
}
.org-label-btn {
  display: none;
}
.org-label:hover {
  .org-label-btn {
    display: inline-flex;
  }
}
</style>